import { MutableRefObject, useCallback, useEffect, useRef } from "react";

/**
 * This hook is used to restore the scroll position of a page when the user calls restoreScroll(), for
 * example when the user navigates back to the page and loads are complete.
 *
 * This should only be used on pages where the
 * browser's built-in scroll restoration does not work as expected.
 * @param key unique key for route to store scroll position
 * @returns {restoreScroll} function to restore scroll position
 */
export default function useScrollRestoration(
  key: string,
  scrollRef?: MutableRefObject<HTMLElement | null>
) {
  const lastKnownScrollPosition = useRef(0);
  const ticking = useRef(false); // Used to throttle the scroll event
  const listener = useCallback(
    (event) => {
      lastKnownScrollPosition.current = event.target.scrollingElement.scrollTop;
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          if (lastKnownScrollPosition.current !== 0) {
            sessionStorage.setItem(
              key,
              lastKnownScrollPosition.current.toString()
            );
          }
          ticking.current = false;
        });
        ticking.current = true;
      }
    },
    [key]
  );
  useEffect(() => {
    const currentScrollRef = scrollRef?.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener("scroll", listener);
    } else {
      document.addEventListener("scroll", listener);
    }
    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener("scroll", listener);
      } else {
        document.removeEventListener("scroll", listener);
      }
    };
  }, [listener, scrollRef]);

  function restoreScroll() {
    const el = scrollRef?.current || window;
    const scrollPosition = sessionStorage.getItem(key);
    if (scrollPosition) {
      el.scroll({
        top: parseInt(scrollPosition),
        left: 0,
        behavior: "instant",
      });
    }
  }
  return { restoreScroll };
}
